<template>
    <div class="app app-background">
        <main-header :large="true">
            <template slot="cards">
                <user-card
                    :first-name="firstName"
                    :last-name="lastName"
                />
                <ride-card
                    :total="totalWeight"
                    :value="totalWeightDelivered"
                    :unit="$t('ton_short')"
                    :description="$t('weight').toUpperCase()"
                    icon="weight-hanging"
                />
                <ride-card
                    :total="totalDeliveries"
                    :value="totalDelivered"
                    :description="$t('deliveries').toUpperCase()"
                    unit=""
                    icon="dolly"
                />
            </template>
        </main-header>

        <datepicker v-model="transportDate" @input="updateTransportData" />

        <ol class="overview">
            <template v-if="! loading && ! message">
                <template v-if="transportRides.length > 0">
                    <ride-view
                        v-for="ride in transportRides"
                        :key="ride.id"
                        :ride="ride"
                        @click="openRide(ride)"
                    />
                </template>
                <template v-else>
                    <li>
                        <p class="dynamic-gray" style="text-align: center;">{{ $t('no_rides_today') }}</p>
                    </li>
                </template>
            </template>
            <template v-else-if="! message">
                <li @click="$emit('click')" class="loading-spinner uk-container uk-container-large">
                    <div data-uk-spinner="ratio: 4" />
                </li>
                <li class="loading-spinner">
                    {{ $t('loading.transport_rides') }}
                </li>
            </template>
            <template v-else>
                <li>
                    <p class="dynamic-gray" style="text-align: center;">{{ message }}</p>
                </li>
                <li v-if="error && error !== ''">
                    <p class="dynamic-gray" style="text-align: center;">{{ error }}</p>
                </li>
                <li>
                    <!-- TODO make button -->
                    <p
                        class="dynamic-gray"
                        style="text-align: center;"
                        @click="tryAgain"
                    >
                        {{ $t('try_again') }}
                    </p>
                </li>
            </template>
        </ol>
    </div>
</template>

<style lang="scss">
    .overview {
        margin: 0;
    }
</style>

<script>
import { apiClient } from '@/api';
import Datepicker    from './datepicker/Datepicker';
import debounce      from 'debounce';
import MainHeader    from '../../../components/header/MainHeader.vue';
import RideCard      from '../../../components/header/headerCards/RideCard';
import RideView      from './ride/RideView';
import roundTo       from '@/support/roundTo';
import UserCard      from '../../../components/header/headerCards/UserCard';
import {
    getBackendDate,
    getDateNumber
}  from '@/support/dateFunctions';
import {
    executeFromVersion,
    setCodeVersion
} from '@/support/codeVersion';
import {
    getSettings,
    setSettings
} from "@/support/settings";

export default {
    name: "Overview",

    components: {
        Datepicker,
        MainHeader,
        RideCard,
        RideView,
        UserCard,
    },
    data()
    {
        return {
            abortController:   null,
            currentDateNumber: getDateNumber(new Date()),
            driver:            null,
            error:             null,
            message:           null,
            settings:          getSettings(),
            transportDate:     new Date(),
            transportRides:    [],
        };
    },
    computed: {
        primaryContactPerson() {
            if (this.driver && this.driver.primaryContactPerson) return this.driver.primaryContactPerson;

            return null;
        },
        firstName() {
            if (! this.driver) return '';

            if (this.primaryContactPerson && this.primaryContactPerson.fullName !== '') {
                return this.primaryContactPerson.firstName !== ''
                    ? this.primaryContactPerson.firstName.trim()
                    : this.primaryContactPerson.fullName.trim()
                ;
            }
            return this.driver.fullCompanyName;
        },
        lastName() {
            if (this.primaryContactPerson) {
                if (this.firstName === this.primaryContactPerson.fullName) return '';

                const lastName = this.primaryContactPerson.lastNamePrefix + ' ' + this.primaryContactPerson.lastName;
                return lastName.trim();
            }
            return '';
        },
        loading() {
            return !! this.abortController;
        },
        totalDeliveries() {
            let deliveries = 0;

            for (const ride of this.transportRides) {
                deliveries += ride.items.length;
            }
            return deliveries;
        },
        totalDelivered() {
            let delivered = 0;

            for (const ride of this.transportRides) {
                if (ride.status.code === 0) {
                    continue;
                } else if (ride.status.code === 5) {
                    delivered += ride.items.length;
                } else {
                    for (const item of ride.items) {
                        let linesDelivered = 0;

                        if (! item.packingSlip) continue;

                        for (const line of item.packingSlip.lines) {
                            if (Number(line.quantityUndelivered) !== 0) {
                                break;
                            }
                            linesDelivered++;
                        }
                        if (item.packingSlip.lines.length === linesDelivered) {
                            delivered++;
                        }
                    }
                }
            }
            return delivered;
        },
        totalWeight() { // Returns the total weight in tonne
            let totalWeight = 0;

            this.transportRides.forEach((transportRide) => {
                totalWeight += Number(transportRide.weight);
            },
                totalWeight
            );
            return roundTo(totalWeight / 1000, 2, true);
        },
        totalWeightDelivered() { // Returns the total weight delivered in tonne
            let totalWeightDelivered = 0;

            for (const ride of this.transportRides) {
                if (ride.status.code !== 0) { // Exclude status concept since it may contain orders (instead of packing slips) and should not have any delivered
                    for (const item of ride.items) {
                        if (! item.packingSlip) continue;

                        for (const line of item.packingSlip.lines) {
                            if (Number(line.quantityUndelivered) === 0) {
                                totalWeightDelivered += Number(line.weight);
                            }
                        }
                    }
                }
            }
            return roundTo(totalWeightDelivered / 1000, 2, true);
        }
    },
    async created() {
        try {
            const response = await apiClient.get('auth/info', { params: { include: 'codeVersion,user.relation:as(minimal),user.relation.primaryContactPerson' }});
            const relation = response.data.user.relation;

            if (! relation) {
                // No relation data found for current user
                this.message = this.$t('no_relation_user');
                return;
            }
            this.driver = relation;
            setCodeVersion(response.data.codeVersion);

        } catch (e) {
            this.error   = e;
            this.message = this.$t('error.logging_in');
            return;
        }
        if (! this.error && executeFromVersion(21, 96)) {
            await this.fetchSettings();
        }
        if (! this.error) {
            await this.fetchRides();
        }
    },
    methods: {
        async fetchRides() {
            try {
                if (this.abortController) {
                    this.abortController.abort();
                }
                this.abortController = new AbortController();

                // Make sure we empty previous error messages since this can be a next attempt
                this.error   = null;
                this.message = null;

                const requestConfig = {
                    signal: this.abortController.signal,
                    params: {
                        page:                     1,
                        perPage:                  999,
                        departureDate:            getBackendDate(this.transportDate),
                        driver:                   this.driver.id,
                        include:                  'items, items.packingSlip.deliveryAddress, status, vehicle',
                        'orderby[departureTime]': 'ASC',
                        'orderby[status]':        'DESC',
                        'orderBy[id]':            'ASC',
                    },
                };
                const result = await apiClient.get('transport/transport_rides', requestConfig);

                this.transportRides  = result.data.data;
                this.abortController = null;

            } catch (e) {
                if (e.__CANCEL__) {
                    return;
                }
                this.abortController = null;
                this.error           = e;
                this.message         = this.$t('error.loading_rides');
            }
        },
        async fetchSettings() {
            try {
                if (this.settings.dateNumber === this.currentDateNumber) {
                    // Only reload settings @ new session (automatically) & when expired (past date)
                    return;
                }
                if (this.abortController) {
                    this.abortController.abort();
                }
                this.abortController = new AbortController();

                // Make sure we empty previous error messages since this can be a next attempt
                this.error   = null;
                this.message = null;

                const result = await apiClient.get('driver/relevant_settings_for_app', { signal: this.abortController.signal });

                // Set date, only load settings once per day (if the same browser tab stays open)
                result.data.dateNumber = this.currentDateNumber;
                setSettings(result.data);

            } catch (e) {
                if (e.__CANCEL__) {
                    return;
                }
                this.abortController = null;
                this.error           = e;
                this.message         = this.$t('error.loading_settings');
            }
        },
        async tryAgain() {
            if (this.message === this.$t('error.loading_rides')) await debounce(this.fetchRides(), 500);

            else window.location.reload();
        },
        openRide(ride) {
            this.$router.push({
                name:   'loading_view',
                params: {
                    rideId: ride.id,
                }
            });
        },
        async updateTransportData() {
            await debounce(this.fetchRides(), 500);
        }
    },
};

</script>
