<template>
    <overlay ref="overlay" @closed="$emit('closed')">
        <div class="datepicker">
            <div class="top">
                <div class="month">
                    <span class="name">
                        {{ monthYearString }}
                    </span>
                    <button class="arrow">
                        <i
                            @click="setPreviousMonth"
                            class="fal fa-chevron-left"
                        />
                    </button>
                    <button class="arrow">
                        <i
                            @click="setNextMonth"
                            class="fal fa-chevron-right"
                        />
                    </button>
                </div>
            </div>
            <div class="calendar">
                <span class="day">{{ $t('weekday.monday_short') }}</span>
                <span class="day">{{ $t('weekday.tuesday_short') }}</span>
                <span class="day">{{ $t('weekday.wednesday_short') }}</span>
                <span class="day">{{ $t('weekday.thursday_short') }}</span>
                <span class="day">{{ $t('weekday.friday_short') }}</span>
                <span class="day">{{ $t('weekday.saturday_short') }}</span>
                <span class="day">{{ $t('weekday.sunday_short') }}</span>
                <button
                    v-for="(date, idx) in datesInMonth"
                    :key="idx"
                    :class="getDateClasses(date)"
                    @click.prevent="selectDate(date)"
                >
                    {{ date.getDate() }}
                </button>
            </div>
        </div>
    </overlay>
</template>

<style lang="scss">

.datepicker {
    background-color: var(--datepicker-background);
    border-radius:    10px;
    padding:          30px;
    margin-top:       100px;

    .top {
        margin-bottom: 40px;

        .month {
            display:         flex;
            justify-content: space-between;
            align-items:     center;

            .name {
                font-weight: 600;
                color:       var(--dynamic-title-color) !important;
                margin-left: 23px;
            }

            .arrow {
                background-color: var(--datepicker-background);
                border:           0;

                i {
                    font-size: 24px;
                    color:     var(--dynamic-blue-white);
                }

                .fa-chevron-left {
                    margin-right: -150px !important;
                }

                .fa-chevron-right {
                    margin-left: -70px !important;
                }
            }
        }
    }

    .calendar {
        display:               grid;
        grid-template-columns: repeat(7, 1fr);
        grid-row-gap:          16px;

        .day {
            color:        var(--dynamic-lightGray-darkGray) !important;
            font-size:    20px;
            font-weight:  500;
            justify-self: center;
        }
    }
}

.date {
    color:            var(--dynamic-title-color);
    padding:          20px;
    width:            70px;
    height:           70px;
    display:          flex;
    align-items:      center;
    justify-content:  center;
    justify-self:     center;
    border-radius:    50%;
    font-size:        24px;
    font-weight:      600;
    border:           2px solid transparent;
    background-color: transparent;
    cursor:           pointer;
}

.faded {
    color:       #cacaca;
    font-weight: 100;
}

.current-day-calendar {
    color:            #FFF;
    background-color: var(--item-blue-to-darkBlue);
    border:           2px solid #FFF;
}

</style>

<script>

import Overlay from '@/components/overlay/Overlay';

export default {
    name: "DatepickerOverlay",

    components: { Overlay },

    props: {
        value: {
            type:     Date,
            required: true
        },
    },

    data() {
        return {
            displayedMonth: new Date(this.value.getFullYear(), this.value.getMonth()),
        }
    },

    computed: {
        datesInMonth: function () {
            // Dates displayed, including first/last dates of previous/next month within the same week as dates in the selected month
            let prevMonth  = new Date(this.displayedMonth);
            prevMonth.setMonth(this.displayedMonth.getMonth() - 1);
            prevMonth      = prevMonth.getMonth();

            const curMonth = this.displayedMonth.getMonth();
            let date       = new Date(this.displayedMonth.getFullYear(), curMonth);

            while (date.getDay() !== 1) {
                // Reduce date to monday of the same week
                date.setDate(date.getDate() - 1)
            }

            let days = [];
            while (date.getMonth() === prevMonth || date.getMonth() === curMonth) {
                // Add dates (previous month dates in the same week as the current month + current month dates)
                days.push(new Date(date));
                date.setDate(date.getDate() + 1);
            }

            while (date.getDay() !== 1) {
                // Add dates of the next month with dates inside the same week as the previous month
                days.push(new Date(date))
                date.setDate(date.getDate() + 1);
            }

            return days;
        },

        monthYearString: function () {
            return this.$options.filters.capitalize(this.displayedMonth.toLocaleDateString('nl-NL', { month: 'long' }) + ' ' + this.displayedMonth.getFullYear());
        }
    },

    methods: {
        getDateClasses(date) {
            let dateClasses = 'date';

            if (date.getMonth() !== this.displayedMonth.getMonth()) dateClasses += ' faded';

            if (
                date.getYear()  === this.value.getYear()  &&
                date.getMonth() === this.value.getMonth() &&
                date.getDate()  === this.value.getDate()
            ) {
                dateClasses += ' current-day-calendar';
            }

            return dateClasses;
        },

        selectDate(date) {
            this.$emit('input', date);
            this.hide();
        },

        setPreviousMonth() {
            this.displayedMonth = new Date(this.displayedMonth.setMonth(this.displayedMonth.getMonth() - 1));
        },

        setNextMonth() {
            this.displayedMonth = new Date(this.displayedMonth.setMonth(this.displayedMonth.getMonth() + 1));
        },

        show()
        {
            this.$refs.overlay.show();
        },

        hide()
        {
            this.$refs.overlay.hide();
        }
    },
};
</script>
