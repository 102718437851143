<template>
    <div>
        <div class="card">
            <header class="uk-card-top">
                <i class="fal fa-user"></i>
            </header>
            <main>
                <h4>{{ firstName }}</h4>
                <p>{{ lastName }}</p>
            </main>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
export default {
    name: "UserCard",

    props: {
        firstName: { type: String, required: true },
        lastName:  { type: String, required: true },
    },
};
</script>
