<template>
        <div class="date-bar">
            <button type="button" class="arrow-left" @click="setPreviousDate">
                <i class="fal fa-chevron-left" />
            </button>
            <button type="button" class="current-day" @click="showOverlay">
                    <i class="fal fa-calendar-alt" />
                    <template class="uk-text-bold">{{ writtenDayString }}</template> {{ datePlusWrittenMonthString }}
            </button>
            <button type="button" class="arrow-right" @click="setNextDate">
                <i class="fal fa-chevron-right" />
            </button>
        </div>
</template>

<style scoped lang="scss">

.date-bar {
    margin:           30px auto;
    display:          flex;
    justify-content:  space-between;
    height:           60px;
    width:            70%;
    border-radius:    5px;
    background-color: var(--item-background);

    button {
        display:    block;
        border:     0;
        margin:     0;
        padding:    0;
        background: unset;
    }

    .current-day {
        @include center();
        width:       350px;
        top:         5px;
        font-size:   18px;
        display:     flex;
        align-items: baseline;
        color:       var(--dynamic-title-color);

        i {
            font-size:    22px;
            margin-right: 15px;
            padding-left: 0;
            padding-top:  12px;
        }

        p {
            margin: 11px 0 0 0;
        }
    }

    .arrow-left,
    .arrow-right {
        background:  var(--item-blue-to-darkBlue);
        width:       70px;
        font-size:   26px;
        color:       #fff;
        padding-top: 15px;
    }

    .arrow-left {
        border-radius: 5px 0 0 5px;
        border-right:  #fff 2px solid;

        i {
            position: relative;
            bottom:   5px;
        }
    }

    .arrow-right {
        border-radius: 0 5px 5px 0;
        border-left:   #fff 2px solid;

        i {
            position: relative;
            bottom:   5px;
        }
    }

}

</style>

<script>

import DatepickerOverlay from './DatepickerOverlay';
import createDialog      from '@/support/createDialog';

export default {
    name: "Datepicker",
    props: {
        value: {
            type:     Date,
            required: true
        },
    },

    data() {
        return {
            transportDate: new Date(this.value),
        };
    },

    computed: {
        datePlusWrittenMonthString: function () {
            return this.transportDate.getDate() + ' ' + this.transportDate.toLocaleDateString('nl-NL', { month: 'long' });
        },
        writtenDayString: function () {
            return this.$options.filters.capitalize(this.transportDate.toLocaleDateString('nl-NL', { weekday: 'long' }));
        }
    },

    methods: {
        setPreviousDate() {
            this.transportDate = new Date(this.transportDate.setDate(this.transportDate.getDate() - 1));
            this.$emit('input', this.transportDate);
        },

        setNextDate() {
            this.transportDate = new Date(this.transportDate.setDate(this.transportDate.getDate() + 1));
            this.$emit('input', this.transportDate);
        },

        async showOverlay() {
            const picker = await createDialog(this, DatepickerOverlay, { value: this.transportDate });

            picker.$on( 'input', (date) => {
                this.transportDate = date;
                this.$emit('input', date);
            });
        }
    }
};
</script>
