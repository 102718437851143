<template>
    <div>
        <div class="card">
            <header class="ride">
                <i :class="`fal fa-${icon}`"></i>
            </header>
            <main>
                <div class="progress-content">
                    <span class="dynamic-title">{{ description }}</span>
                    <progress
                        v-if="total !== null"
                        class="uk-progress"
                        :class="progressClass"
                        :value="value"
                        :max="total"
                    />
                    <h5>{{ total }} {{ unit }}</h5>
                </div>
            </main>
        </div>
    </div>
</template>

<style lang="scss">

.card {
    .ride {
        i {
            margin-left: -2px;
        }
    }
}

.progress {
    &-11::-webkit-progress-value  { background: $progress-0-11 !important; }
    &-22::-webkit-progress-value  { background: $progress-11-22 !important; }
    &-33::-webkit-progress-value  { background: $progress-22-33 !important; }
    &-44::-webkit-progress-value  { background: $progress-33-44 !important; }
    &-55::-webkit-progress-value  { background: $progress-44-55 !important; }
    &-66::-webkit-progress-value  { background: $progress-55-66 !important; }
    &-77::-webkit-progress-value  { background: $progress-66-77 !important; }
    &-88::-webkit-progress-value  { background: $progress-77-88 !important; }
    &-99::-webkit-progress-value  { background: $progress-88-99 !important; }
    &-100::-webkit-progress-value { background: $progress-100   !important; }
}

</style>

<script>
export default {
    name: "RideCard",
    props: {
        description: { type: String, required: true },
        icon:        { type: String, required: true },
        total:       { type: Number, required: true },
        unit:        { type: String, required: true },
        value:       { type: Number, required: true },
    },

    computed: {
        progressClass() {
            const percentage = this.value / this.total;

            if (percentage <= 0.11) return 'progress-11';
            if (percentage <= 0.22) return 'progress-22';
            if (percentage <= 0.33) return 'progress-33';
            if (percentage <= 0.44) return 'progress-44';
            if (percentage <= 0.55) return 'progress-55';
            if (percentage <= 0.66) return 'progress-66';
            if (percentage <= 0.77) return 'progress-77';
            if (percentage <= 0.88) return 'progress-88';
            if (percentage <= 0.99) return 'progress-99';
            if (percentage >= 1)    return 'progress-100';
            return 'progress-11';
        }
    }
};
</script>
