<template>
    <li @click="$emit('click')" class="ride uk-container uk-container-large">
        <div class="card">
            <div class="left" :style="'background: ' + ride.status.color" />
            <div class="icon">
                <i class="fal fa-truck-moving"></i>
                <p>{{ ride.id }}</p>
            </div>
            <div class="middle">
                <div class="top">
                    <h3 class="time">{{ ride.departureTime ? ride.departureTime : '--:--' }}</h3>
                    <p class="vehicle uk-text-truncate">{{ vehicleName }}</p>
                    <span class="delivery uk-text-truncate">({{ ride.items.length }} Leveringen)</span>
                </div>
                <div class="location">
                    <span class="city">
                        {{ deliveryPlacesString }}
                    </span>
                </div>
            </div>
            <div class="right" :style="'color: ' + ride.status.color">
                <p class="uk-text-truncate">{{ ride.status.description }}</p>
            </div>
        </div>
    </li>
</template>

<style scoped lang="scss">

.ride {
    .card {
        display:               grid;
        grid-template-columns: 15px 100px 1fr 1fr;
        width:                 100%;
        height:                100px;
        align-items:           center;
        border-radius:         5px;
        background-color:      var(--item-background);
        border-bottom:         1px solid var(--item-background);

        .left {
            border-radius: 5px 0 0 5px;
        }

        .icon {
            margin: 5px 0 0 20px;

            p {
                margin:    0;
                font-size: 16px;
                color:     var(--dynamic-title-gray-color);
            }

            i {
                font-size: 28px;
                color:     var(--dynamic-title-color);
            }
        }

        .middle {
            display:   block;
            max-width: 200px;

            .top {
                display:     flex;
                width:       400px;
                align-items: center;
                gap:         5px;

                h3,
                p,
                span {
                    color: var(--dynamic-title-color);
                }

                p {
                    margin:    0;
                    font-size: 18px;
                    max-width: 150px;
                }

                h3 {
                    font-weight: 600;
                    margin:      0;
                    text-align:  left;
                }

                span {
                    font-size:     18px;
                    overflow:      hidden;
                    text-overflow: ellipsis;
                    white-space:   nowrap;
                    max-width:     100px;
                }

                .vehicle {
                    max-width: 120px;
                }

                .delivery {
                    min-width: 120px;
                }

                .time {
                    margin-right: 10px;
                    width:        60px;
                    text-align:   center;
                }
            }

            .location {
                width:    400px;
                overflow: hidden;

                span {
                    font-size: 16px;
                    color:     var(--dynamic-title-lightGray-color);
                }
            }

        }

        .right {

            p {
                font-size:  22px;
                margin:     0 20px 0;
                text-align: right;
            }
        }

    }
}

.status-geladen {
    background: $theme-blue;
}

.status-geladen-text p {
    color: $theme-blue;
}

.status-completed {
    background: $completed !important;
}

.status-completed-text p {
    color: #2DCE98 !important;
}

.status-disabled {
    background: #cacaca;
}

.status-disabled-text p,
.status-disabled-text span,
.status-disabled-text h3 {
    color: #cacaca !important;
}

</style>

<script>
export default {
    name: "RideView",

    props: {
        ride: {
            type:     Object,
            required: true
        },
    },
    computed: {
        vehicleName: function () {
            return this.ride.vehicle !== null
                ? this.ride.vehicle.name
                : ''
            ;
        },
        deliveryPlaces: function () {
            let deliveryPlaces = [];
            this.ride.items.forEach((item) => {
                if (item.packingSlip !== null) {
                    if (! deliveryPlaces.includes(item.packingSlip.deliveryAddress.city)) {
                        deliveryPlaces.push(item.packingSlip.deliveryAddress.city);
                    }
                }
            },
                deliveryPlaces
            );

            return deliveryPlaces;
        },
        deliveryPlacesString: function () {
            let i       = 0;
            const total = this.deliveryPlaces.length;
            let string  = '';
            while (i < total) {
                string += this.deliveryPlaces[i];
                if (i + 1 < total) {
                    string += ' | ';
                }
                i++;
            }
            return string;
        }
    },
    methods: {
        /**
         * @param {string} city
         * @param {number} idx
         * @param {number} total
         *
         * @return {string}
         */
        getCityString(city, idx, total) {
            return idx + 1 < total
                ? city + ' | '
                : city
            ;
        },
    }
};
</script>
